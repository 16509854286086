import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  Auth,
  GoogleAuthProvider,
  IdTokenResult,
  signInWithPopup,
  signOut,
  UserCredential,
} from '@angular/fire/auth';

import { StorageService } from '@core/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ngZone: NgZone = inject(NgZone);
  private readonly router: Router = inject(Router);
  private readonly auth: Auth = inject(Auth);
  private readonly storageService: StorageService = inject(StorageService);

  public logOut(): void {
    from(signOut(this.auth)).subscribe(() => {
      this.storageService.removeAll();
      this.redirectToLogin();
    });
  }

  public getUserName(): string {
    return this.auth.currentUser?.displayName || '';
  }

  public getEmail(): string {
    return this.auth.currentUser?.email || '';
  }

  public getUserId(): string {
    return this.auth.currentUser?.uid || '';
  }

  public redirectToHome(): void {
    this.ngZone.run(() => this.router.navigateByUrl('/admin'));
  }

  public redirectToLogin(): void {
    this.ngZone.run(() => this.router.navigate(['/auth/login']));
  }

  public loginWithGoogle(): Observable<IdTokenResult | null> {
    return from(signInWithPopup(this.auth, new GoogleAuthProvider())).pipe(
      switchMap((user: UserCredential) => from(user?.user?.getIdTokenResult()))
    );
  }

  public refreshToken(): Observable<IdTokenResult | null> {
    if (this.auth.currentUser) {
      return from(this.auth.currentUser?.getIdTokenResult(true));
    }
    return EMPTY;
  }

  public getAuthToken(): string | null {
    const userAuthenticated: IdTokenResult = this.storageService.getAndConvertToObject(
      'session'
    ) as IdTokenResult;
    return userAuthenticated?.token || null;
  }

  public saveUserSession(session: IdTokenResult): void {
    this.storageService.setObject('session', session);
  }
}
